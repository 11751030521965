$hover-transition: all 0.25s ease;
$g-border-radius: 0.75rem;
$g-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
$main-color: rgb(186, 228, 246);
$footer-color: #42576d;
$footer-bottom-color: rgb(191, 212, 221);
$announcement-title-color: rgb(179, 217, 233);
$table-header-bg-color: rgb(211, 224, 231);
// $form-block-bg-color: #eae89d;
$form-block-bg-color: #d4e8ef;
$page-block-bg-color: #ebebeb;
// $form-block-bg-color: #f9fcd8;
