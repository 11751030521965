@import "variables";
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    min-height: 100svh;
    position: relative;
    overflow-x: hidden;
}

.page {
    width: 100vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    .page-content {
        position: relative;
        width: 80vw;
        height: fit-content;
        background-color: #fbfbfb;
        border-bottom-left-radius: $g-border-radius;
        border-bottom-right-radius: $g-border-radius;
        box-shadow: $g-shadow;
        margin-bottom: 250px;
    }
}

.submit-btn {
    background-color: #f7dc11;
    border-color: #f7dc11;
    &:hover {
        background-color: #ffc107;
        border-color: #ffc107;
        transition: $hover-transition;
    }
}

.refresh-btn {
    background-color: #6ea7ec;
    border-color: #6ea7ec;
    &:hover {
        background-color: #0a58ca;
        border-color: #0a58ca;
        transition: $hover-transition;
    }
}

.btn {
    border-radius: $g-border-radius;
}

@media screen and (max-width: 992px) {
    .page {
        .page-content {
            width: 95vw;
        }
    }
}

@media screen and (max-width: 660px) {
    .page {
        .page-content {
            width: 95vw;
            margin-bottom: 225px;
        }
    }
}

.ql-indent-1 {
    text-indent: 36px;
}
.ql-indent-2 {
    text-indent: 72px;
}
